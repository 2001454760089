import {
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clickOnPay, setProcessing } from "../../../Redux/cartSlice";

const CardSetupForm = () => {
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const payClick = useSelector((state) => state.payClick);
    const form = useRef();

    useEffect(() => {
        dispatch(setProcessing(true));
    }, [])

    useEffect(() => {
        if(!!payClick) {
            form.current && form.current.requestSubmit();
            dispatch(clickOnPay(false));
        }
    }, [payClick])

    const handleClick = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        dispatch(setProcessing(true));
        const res = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/thank-you`,
            },
        });
        const { error } = res;
        if (!!error) {
            setError(error.message);
        }
        dispatch(setProcessing(false));
    };

    const onReadyHandler = () => {
        dispatch(setProcessing(false));
    };

    
   // if (paymentSucceeded) return <PaymentComplete last4={last4} />;
    return (
        // The actual checkout form, inside the !paymentSucceeded clause
        <div className={`print-payment-form`}>
            <div className={`print-payment-desc`}>
                <div className="print-payment-element">
                    {
                        // TODO: Integrate Stripe
                        <form onSubmit={handleClick} id="payment-element" ref={form}>
                            <PaymentElement
                                onReady={onReadyHandler}
                            />
                        </form>
                    }
                </div>
                {error && (
                    <div
                        className="sr-field-error"
                        id="card-errors"
                        role="alert"
                    >
                        <div className="card-error" role="alert">
                            {error}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default CardSetupForm;
