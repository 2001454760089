import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    value: {
      id: '', 
      cart: {},
      product: {
        finish: "Luster Finish",
        protectiveCoating: true,
        frames: true,
        shippingTime: "normal",
        morePhotos: false,
      },
      prints: [],
      order: {},
      fetchedInfo: [],
    },
    payClick: false,
    processing: false,
    extraItem: {
      items: [],
      extra_prints: []
    }
  },
  reducers: {
    updateID: (state, action) => {
      state.value.id = action.payload;
    },
    updateCart: (state, action) => {
      state.value.cart = { ...action.payload };
    },
    updateProduct: (state, action) => {
      state.value.product = { ...action.payload };
    },
    updatePrints: (state, action) => {
      state.value.prints = [...action.payload];
    },
    updateOrder: (state, action) => {
      state.value.order = { ...action.payload };
    },
    updateFetchedInfo: (state, action) => {
      state.value.fetchedInfo = action.payload;
    },
    clickOnPay: (state, action) => {
      state.payClick = action.payload;
    },
    setProcessing: (state, action) => {
      state.processing = action.payload;
    },
    setExtraItems: (state, action) => {
      state.extraItem = action.payload;
    }
  },
});

export const {
  updateCart,
  updateProduct,
  updatePrints,
  updateOrder,
  updateFetchedInfo,
  updateID,
  clickOnPay,
  setProcessing,
  setExtraItems
} = cartSlice.actions;

export default cartSlice.reducer;
