import { useNavigate, useSearchParams } from "react-router-dom";
import Stepper from "../../Common/Stepper";
import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

export const ThankYou = () => {
    const [searchParam] = useSearchParams();
    const [message, setMessage] = useState("");
    const [processing, setProcessing] = useState(true);
    const navigate = useNavigate();
    const [stripePromise, setStripePromise] = useState(null);

    const checkstatus = () => {
        const clientSecret = searchParam.get("payment_intent_client_secret");
        setProcessing(true);
        stripePromise
            .retrievePaymentIntent(clientSecret)
            .then(({ paymentIntent }) => {
                if (!!paymentIntent) {
                    switch (paymentIntent.status) {
                        case "succeeded":
                            setMessage("Success! Payment received.");
                            break;

                        case "processing":
                            setMessage(
                                "Payment processing. We'll update you when payment is received."
                            );
                            break;

                        case "requires_payment_method":
                            setMessage(
                                "Payment failed. Please try another payment method."
                            );
                            setTimeout(() => {
                                navigate("/order");
                            }, 3000);
                            break;

                        default:
                            setMessage("Something went wrong.");
                            break;
                    }
                } else {
                    setMessage("Something went wrong.");
                }

                setProcessing(false);
            });
    };

    useEffect(() => {
        if (!!stripePromise) {
            checkstatus();
        }
    }, [stripePromise]);

    useEffect(() => {
        async function setUp() {
            try {
                const key = await fetch(
                    "https://forms.memorycherish.com/api/stripe-public-key"
                ).then((res) => res.text());
                let stripeConfig = await loadStripe(key);
                setStripePromise(stripeConfig);
            } catch (error) {
                console.error("Config error", error.message);
            }
        }
        setUp();
    }, []);
    return (
        <div className="lg:flex justify-between">
            <Stepper />
            <div className="lg:w-[calc(100%-360px)] lg:bg-[#FBF4E3]">
                <div className="w-11/12 xl:w-4/5 mx-auto mb-10">
                    <h1 className="mt-8 lg:mt-32 mb-6 lg:mb-12 text-3xl lg:text-4xl font-semibold text-black text-center">
                        Thanks
                    </h1>
                    {!!processing && (
                        <div className="text-xl flex justify-center content-center items-center">
                            <svg
                                className="animate-spin ml-1 mr-3 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    stroke-width="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                            Please wait...
                        </div>
                    )}
                    {!processing && (
                        <div className="text-xl flex justify-center content-center items-center">
                            {message}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ThankYou;
