import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateProduct, updatePrints } from "../../Redux/cartSlice";
import { useState } from "react";

const ProductDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.value);
  const [productDetails, setProductDetails] = useState({ ...state.product });
  const [fetchedInfo, setFetchedInfo] = useState(state.fetchedInfo );
  const [prints, setPrints] = useState([...state.prints]);

  const handleSubmit = () => {
    dispatch(updateProduct(productDetails));
    dispatch(updatePrints(prints));
    if (productDetails.frames) {
      navigate("/framed-prints");
    } else {
      navigate("/order");
    }
  };

  const handlePrevSubmit = () => {
    if (state.id) {
      navigate("/"+state.id);
    }
  };

  return (
    <div className="lg:flex justify-between">
      <div className="lg:w-[calc(100%-360px)] lg:bg-[#FBF4E3]">
        <div className="w-11/12 xl:w-4/5 mx-auto mb-10">
          <h1 className="mt-8 lg:mt-32 mb-6 lg:mb-12 text-3xl lg:text-4xl font-semibold text-black text-center">
            2. Product  new form
          </h1>
          <div className="flex justify-end mt-10 justify-between">
          <button
              onClick={() => {
                handlePrevSubmit();
              }}
              className="bg-[#FF9728] rounded-xl text-xl text-white font-semibold py-2 lg:py-3 px-6"
            >
              Prev
            </button>
            <button
              onClick={() => {
                handleSubmit();
              }}
              className="bg-[#FF9728] rounded-xl text-xl text-white font-semibold py-2 lg:py-3 px-6"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
