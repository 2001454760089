import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "../../../Redux/cartSlice";
import PhoneNumber from "./PhoneNumber";
import StateSelect from "./StateSelect";
import Star0 from "../../../Assets/images/stars-0.svg";
import Star1 from "../../../Assets/images/stars-1.svg";
import Star2 from "../../../Assets/images/stars-2.svg";
import Star3 from "../../../Assets/images/stars-3.svg";
import Star4 from "../../../Assets/images/stars-4.svg";
import Star5 from "../../../Assets/images/stars-5.svg";

const Form = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.value);
  const [deliveryDetails, setDeliveryDetails] = useState({ ...state.cart, rating: 0 });
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

  const handleRating = (rate) => {
    setDeliveryDetails({
      ...deliveryDetails,
      customer_rating: rate,
    });
    setRating(rate);
  };

  const getStarImage = () => {
    const starValue = hover || rating;
    switch (starValue) {
      case 1:
        return Star1;
      case 2:
        return Star2;
      case 3:
        return Star3;
      case 4:
        return Star4;
      case 5:
        return Star5;
      default:
        return Star0;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateCart(deliveryDetails));
    navigate("/product");
  };

  useEffect(() => {
    if (data) {
      setDeliveryDetails({
        firstname: data.firstName,
        lastname: data.lastName,
        addressLine1: data.address,
        addressLine2: "",
        phoneCode: data.phone ? data.phone : "",
        phoneNumber: data.phone ? data.phone.slice(2) : "",
        state: data.state,
        city: data.city,
        zip: data.zip,
        email: data.email,
        customer_rating: 0,
      });
    }
  }, [data]);

  return (
    <div className="lg:w-[calc(100%-360px)] lg:bg-[#FBF4E3]">
      <div className="w-11/12 lg:w-4/5 mx-auto mb-10 lg:mb-20">
        <h1 className="mt-8 lg:mt-32 mb-6 lg:mb-12 text-3xl lg:text-4xl font-semibold text-black text-center">
          1. Delivery Address
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="grid lg:grid-cols-2 gap-5 lg:gap-8 mb-5">
            <div>
              <p className="text-base text-black mb-2">First name</p>
              <input
                className="w-full rounded-xl border border-[#767676] py-3 px-5 outline-none"
                placeholder="Jonah "
                type="text"
                onChange={(e) => {
                  setDeliveryDetails({
                    ...deliveryDetails,
                    firstname: e.target.value,
                  });
                }}
                value={deliveryDetails.firstname}
              />
            </div>
            <div>
              <p className="text-base text-black mb-2">Last name</p>
              <input
                className="w-full rounded-xl border border-[#767676] py-3 px-5 outline-none"
                placeholder="Rathmer"
                type="text"
                onChange={(e) => {
                  setDeliveryDetails({
                    ...deliveryDetails,
                    lastname: e.target.value,
                  });
                }}
                value={deliveryDetails.lastname}
              />
            </div>
          </div>
          <div className="mb-5">
            <p className="text-base text-black mb-2">Address Line 1</p>
            <textarea
              className="w-full h-20 lg:h-[50px] rounded-xl border border-[#767676] py-3 px-5 outline-none resize-none"
              placeholder="15205 North Kierland Blvd. Suite 100. Scottsdale."
              onChange={(e) => {
                setDeliveryDetails({
                  ...deliveryDetails,
                  addressLine1: e.target.value,
                });
              }}
              value={deliveryDetails.addressLine1}
            />
          </div>
          <div className="mb-5">
            <p className="text-base text-black mb-2">Address Line 2</p>
            <textarea
              className="w-full h-20 lg:h-[50px] rounded-xl border border-[#767676] py-3 px-5 outline-none resize-none"
              placeholder="15205 North Kierland Blvd. Suite 100. Scottsdale."
              onChange={(e) => {
                setDeliveryDetails({
                  ...deliveryDetails,
                  addressLine2: e.target.value,
                });
              }}
              value={deliveryDetails.addressLine2}
            />
          </div>
          <div className="grid lg:grid-cols-2 gap-5 lg:gap-8 mb-5">
            <div className="">
              <p className="text-base text-black mb-2">State/Province</p>
              <StateSelect
                orderState={deliveryDetails.state}
                onChange={(e) => {
                  setDeliveryDetails({
                    ...deliveryDetails,
                    state: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <p className="text-base text-black mb-2">City</p>
              <input
                className="w-full rounded-xl border border-[#767676] py-3 px-5 outline-none"
                placeholder="Los Angeles"
                type="text"
                onChange={(e) => {
                  setDeliveryDetails({
                    ...deliveryDetails,
                    city: e.target.value,
                  });
                }}
                value={deliveryDetails.city}
              />
            </div>
          </div>
          <div className="grid lg:grid-cols-2 gap-5 lg:gap-8 mb-5">
            <div>
              <p className="text-base text-black mb-2">ZIP/Postal Code</p>
              <input
                className="w-full rounded-xl border border-[#767676] py-3 px-5 outline-none"
                placeholder="90213"
                type="text"
                onChange={(e) => {
                  setDeliveryDetails({
                    ...deliveryDetails,
                    zip: e.target.value,
                  });
                }}
                value={deliveryDetails.zip}
              />
            </div>
            <div>
              <p className="text-base text-black mb-2">Phone Number</p>
              <div className="w-full relative home-phone">
                <PhoneNumber
                  deliveryDetails={deliveryDetails}
                  setDeliveryDetails={setDeliveryDetails}
                />
                <input
                  className="absolute w-full rounded-xl pl-[160px] z-10 py-3 px-5 border border-[#767676] outline-none"
                  placeholder="234-567-8912"
                  type="text"
                  onChange={(e) => {
                    setDeliveryDetails({
                      ...deliveryDetails,
                      phoneNumber: e.target.value,
                    });
                  }}
                  value={deliveryDetails.phoneNumber}
                />
              </div>
            </div>
            <div>
              <p className="text-base text-black mb-2">How was your experience with MemoryCherish?</p>
              <div className="w-full relative home-phone">
                <div className="star-rating">
                  {[...Array(5)].map((_, index) => {
                    const ratingValue = index + 1;
                    return (
                      <span
                        key={index}
                        className={`star star${ratingValue} ${ratingValue <= (hover || rating) ? 'filled' : ''}`}
                        onClick={() => handleRating(ratingValue)}
                        onMouseEnter={() => setHover(ratingValue)}
                        onMouseLeave={() => setHover(0)}
                      >
                        ★
                      </span>
                    );
                  })}
                </div>
                <div className="stars-image">
                  <img src={getStarImage()} alt={`Star ${hover || rating}`} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-20">
            <button
              className="bg-[#FF9728] rounded-xl text-xl text-white font-semibold py-2 lg:py-3 px-6"
              type="submit"
            >
              Select My Order Details
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
