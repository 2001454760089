import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardSetupForm from "./CardSetupForm";
import { useDispatch, useSelector } from "react-redux";
import { clickOnPay, setProcessing } from "../../../Redux/cartSlice";
import { iconMap } from "../../../Data/utils";
import { useNavigate } from "react-router-dom";

const PaymentDetails = ({ state, totalPrice }) => {
    const navigate = useNavigate();
    const [cards, setCards] = useState([]);
    const [isNewCard, setIsNewCard] = useState(false);
    const [selectedCard, setSelectedCard] = useState({});

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const processing = useSelector((state) => state.processing);
    const extraItems = useSelector((state) => state.extraItem);

    let appearance = { theme: "stripe" };
    const dispatch = useDispatch();

    useEffect(() => {
        async function setUp() {
            try {
                dispatch(setProcessing(true));
                const key = await fetch(
                    "https://forms.memorycherish.com/api/stripe-public-key"
                ).then((res) => res.text());
               let stripeConfig = await loadStripe(key); 
                setStripePromise(stripeConfig);
            } catch (error) {
                console.error("Config error", error.message);
            } finally {
                dispatch(setProcessing(false));
            }
        }
        getSavedCardMethod();
        setUp();
    }, []);

    const getSavedCardMethod = async () => {
        try {
            dispatch(setProcessing(true));
            const res = await fetch(
                "https://forms.memorycherish.com/api/payment-methods",
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        customer: state.id,
                    }),
                }
            );
            const result = await res.json();
            if(result.data.payment_methods.length > 0) {
                setCards(result.data.payment_methods);
            }
        } catch (error) {
            console.error("Payment fetch error", error.message);
        } finally {
            dispatch(setProcessing(false));
        }
    };

    const payzerosendWebhookData = async () => {
        handleNewCardSelection();
    }

    const sendWebhookData = () => {
        if (isNewCard) {
            dispatch(clickOnPay(true));
            dispatch(setProcessing(true));
        } else {
            payWithOldCardCardSelection();
            dispatch(setProcessing(true));
        }

        // fetch("https://hook.us1.make.com/2fiwxy7jhk62cbkti359a337ae2perqo", {
        //     fetch("https://hook.us1.make.com/j99pbfyebl4x2x220yg96eox54lrd2o3", {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify(state),
        // })
        //     .then((response) => {
        //         if (!response.ok) {
        //             throw new Error("Network response was not ok");
        //         }
        //         return response.json();
        //     })
        //     .then((data) => {
        //         console.log("Webhook response:", data);
        //     })
        //     .catch((error) => {
        //         console.error("Error sending data to the webhook:", error);
        //     });
    };
    const getProducts = () => {
        // var products = {};
        // for (let key in state.prints[0].product0) {
        //     if (state.prints[0].product0.hasOwnProperty(key)) {
        //         if (state.prints[0].product0[key] && state.prints[0].product0[key].qty && state.prints[0].product0[key].qty > 0) {
        //             products["product0_" + key] = state.prints[0].product0[key].qty;
        //         }
        //     }
        // }
        // for (let key in state.prints[1].product1) {
        //     if ( state.prints[1].product1.hasOwnProperty(key)) {
        //         if (state.prints[1].product0[key] && state.prints[1].product1[key].qty && state.prints[1].product1[key].qty > 0) {
        //             products["product1_" + key] =
        //                 state.prints[1].product1[key].qty;
        //         }
        //     }
        // }
        var products = {};
        for (let i = 0; i < state.prints.length; i++) {
            let productKey = "product" + i + "_";

            for (let key in state.prints[i]["product" + i]) {
                if (state.prints[i]["product" + i].hasOwnProperty(key)) {
                    if (state.prints[i]["product" + i][key] && state.prints[i]["product" + i][key].qty && state.prints[i]["product" + i][key].qty > 0) {
                        products[productKey + key] = state.prints[i]["product" + i][key].qty;
                    }
                }
            }
        }

        return products;
    };

    const checkForWallet = () => {
        var isWallet = {};        
        const prints = state.prints;
        prints.forEach((print, index) => {
            if (print[`product${index}`].isWallet !== 0) {
                isWallet[`product${index}`] = "1";
            }
        });
        return isWallet;
    }

    const getFrames = () => {
        const test_frames = state.prints;
        var frame_array = {};
    
        test_frames.forEach((item, index) => {
            if (test_frames[index][`product${index}`]["framed"] === true) {
                const key = test_frames[index][`product${index}`]["productChild"] || Object.keys(test_frames[index])[0];
                const value = test_frames[index][`product${index}`]["size"];
                const price = test_frames[index][`product${index}`]["price"];
                const color = test_frames[index][`product${index}`]["color"];
                const strValue = String(value);
                const pricestrValue = String(price);
                const colorstrValue = String(color);

                if (frame_array["frame_size_"+key]) {
                    frame_array["frame_size_"+key] += `, ${strValue}`;
                    frame_array["frame_price_"+key] += `, ${pricestrValue}`;
                    frame_array["frame_color_"+key] += `, ${colorstrValue}`;
                } else {
                    frame_array["frame_size_"+key] = strValue;
                    frame_array["frame_price_"+key] = pricestrValue;
                    frame_array["frame_color_"+key] = colorstrValue;
                }
            }
        });
    
        return frame_array;
    }
    
    const handleNewCardSelection = async () => {
        try {
            dispatch(setProcessing(true));

            const res = await fetch(
                "https://forms.memorycherish.com/api/create-payment-intent",
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        amount: totalPrice,
                        zoho_id: state.id,
                        cart: state.cart,
                        is_wallet : checkForWallet(),
                        email: state.cart.email,
                        metadata: {
                            zoho_id: state.id,
                            ...state.product,
                            ...getProducts(),
                            ...getFrames()
                        },
                        extraPrints: extraItems,
                    }),
                }
            );


            const result = await res.json();
            if( result && result.message == "Missing Paramater - Amount" ){
                navigate(`/thank-you-page`);
            }else{
                const {
                    data: {
                        payment_intent: { client_secret },
                    },
                } = result;

                if (window.thoughtmetric) {
                    // Fire ThoughtMetric order event
                    window.thoughtmetric('event', 'order', {
                        transaction_id: result.data.payment_intent.id,
                        total_price: result.data.payment_intent.amount / 100, 
                        currency: result.data.payment_intent.currency.toUpperCase(),
                        orderCurrency: result.data.payment_intent.currency.toUpperCase(),
                        subtotal_price: result.data.payment_intent.amount / 100, 
                        total_discounts: 0.00, 
                        item_quantity: 0.00, 
                        items: [ result.data.payment_intent.metadata ],
                    });

                    window.thoughtmetric('identify', state.cart.email, {
                        email: state.cart.email,
                        created_at: new Date().toString(),
                        total_spent: result.data.payment_intent.amount / 100,
                        first_name: state.cart.firstname,
                        last_name: state.cart.lastname,
                        city: state.cart.city,
                        state: state.cart.state,
                        country: result.data.payment_intent.shipping.address.country,
                        zip: state.cart.zip,
                        phone: state.cart.phoneNumber
                    });
                }
                
                setClientSecret(client_secret);
                dispatch(setProcessing(false));
            }
        } catch (error) {
            // navigate(`/thank-you-page`);
            console.error("Errro", error.message);
        } finally {
            dispatch(setProcessing(false));
        }
    };

    const payWithOldCardCardSelection = async () => {
        try {
            dispatch(setProcessing(true));
            const res = await fetch(
                "https://forms.memorycherish.com/api/confirm-payment-method",
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        amount: totalPrice,
                        zoho_id: state.id,
                        cart: state.cart,
                        is_wallet : checkForWallet(),
                        payment_method: selectedCard.id,
                        return_url: `${window.location.origin}/thank-you`,
                        metadata: {
                            zoho_id: state.id,
                            ...state.product,
                            ...getProducts(),
                            ...getFrames()
                        },
                        extraPrints: extraItems
                    }),
                }
            );

            const result = await res.json();
            if( result && result.message == "Missing Paramater - Amount" ){
                navigate(`/thank-you-page`);
            }
            else    
            {
                const {
                    data: { payment_intent: {id, client_secret, status} },
                } = result;

                if (window.thoughtmetric) {
                    // Fire ThoughtMetric order event
                    window.thoughtmetric('event', 'order', {
                        transaction_id: result.data.payment_intent.id,
                        total_price: result.data.payment_intent.amount / 100, 
                        currency: result.data.payment_intent.currency.toUpperCase(),
                        orderCurrency: result.data.payment_intent.currency.toUpperCase(),
                        subtotal_price: result.data.payment_intent.amount / 100, 
                        total_discounts: 0.00, 
                        item_quantity: 0.00, 
                        items: [ result.data.payment_intent.metadata ],
                    });

                    window.thoughtmetric('identify', state.cart.email, {
                        email: state.cart.email,
                        created_at: new Date().toString(),
                        total_spent: result.data.payment_intent.amount / 100,
                        first_name: state.cart.firstname,
                        last_name: state.cart.lastname,
                        city: state.cart.city,
                        state: state.cart.state,
                        country: result.data.payment_intent.shipping.address.country,
                        zip: state.cart.zip,
                        phone: state.cart.phoneNumber
                    });
                    
                }

                navigate(`/thank-you?payment_intent=${id}&payment_intent_client_secret=${client_secret}&redirect_status=${status}`);
            }
        } catch (error) {
            console.error("Errro", error.message);
        } finally {
            dispatch(setProcessing(false));
        }
    };

    return (
        <div className="mt-4 rounded-xl lg:rounded-2xl shadow-mobile-card bg-white py-1.5 lg:py-2 px-2 lg:px-3">
            <h1 className="text-xl lg:text-3xl font-semibold px-6 py-4">
                Payment Details
            </h1>
            <div className="border-t-4 border-[#F7780F] mb-6 lg:mb-10">
                <div className="w-11/12 lg:w-4/5 mx-auto">
                    <div className="mt-4 lg:mt-6">
                        {!!cards &&
                            cards.map((item, index) => (
                                <label key={"card_" + index}>
                                    <div className="flex justify-between bg-[#F5F5F5] rounded-xl py-3 px-4 mb-3 cursor-pointer">
                                        <div className="flex items-center">
                                            <input
                                                onClick={() => {
                                                    setSelectedCard(item);
                                                    setIsNewCard(false);
                                                }}
                                                checked={
                                                    item.id === selectedCard.id
                                                }
                                                type="radio"
                                            />
                                            <img
                                                className="ml-2 md:ml-6 w-8 md:w-auto h-10"
                                                src={iconMap[item.card.brand]}
                                                alt=""
                                            />
                                            <p className="font-medium text-base lg:text-xl ml-2 md:ml-8">
                                                {"**** **** **** " +
                                                    item.card.last4}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="text-[#00000080] text-base lg:text-xl">
                                                {`${item.card.exp_month}/${item.card.exp_year}`}
                                            </p>
                                        </div>
                                    </div>
                                </label>
                            ))}

                        <label>
                            <div className="flex justify-between bg-[#F5F5F5] rounded-xl py-3 px-4 mb-3 cursor-pointer">
                                <div className="flex items-center">
                                    <input
                                        onClick={() => {
                                            setSelectedCard("");
                                            setIsNewCard(!isNewCard);
                                            handleNewCardSelection();
                                        }}
                                        checked={isNewCard}
                                        type="radio"
                                    />

                                    <p className="font-medium text-base lg:text-xl ml-2 md:ml-8">
                                        Enter New Card
                                    </p>
                                </div>
                            </div>
                        </label>
                    </div>
                    {
                    isNewCard && clientSecret && (
                        <Elements
                            stripe={stripePromise}
                            options={{ appearance, clientSecret }}
                        >
                            <CardSetupForm />
                        </Elements>
                    )}
                    <div className="md:w-1/2 mx-auto mt-6">
                        {!processing && totalPrice > 0 && (
                            <button
                                disabled={ !selectedCard.id && !clientSecret }
                                onClick={() => {
                                    sendWebhookData();
                                }}
                                className="bg-[#FF9728] w-full rounded-full text-xl text-white font-semibold py-3 lg:py-4 px-6 shadow-mobile-card flex justify-center content-center items-center"
                            >
                                Pay ${totalPrice}
                            </button>
                        )}

                        { !processing && totalPrice == 0 && (
                            <button
                                onClick={() => {
                                    payzerosendWebhookData();
                                }}
                                className="bg-[#FF9728] w-full rounded-full text-xl text-white font-semibold py-3 lg:py-4 px-6 shadow-mobile-card flex justify-center content-center items-center"
                            >
                                Pay ${totalPrice}
                            </button>
                        )}

                        {!!processing && (
                            <button
                                type="button"
                                className="bg-[#FF9728] w-full rounded-full text-xl text-white font-semibold py-3 lg:py-4 px-6 shadow-mobile-card flex justify-center content-center items-center"
                                disabled
                            >
                                <svg
                                    className="animate-spin ml-1 mr-3 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        stroke-width="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Processing...
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentDetails;
