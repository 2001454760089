import { useNavigate, useSearchParams } from "react-router-dom";
import Stepper from "../../Common/Stepper";
import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

export const ThankYou2 = () => {
    const [searchParam] = useSearchParams();
    const [message, setMessage] = useState("");
    const [processing, setProcessing] = useState(true);
    const navigate = useNavigate();
    const [stripePromise, setStripePromise] = useState(null);


    return (
        <div className="lg:flex justify-between">
            <Stepper />
            <div className="lg:w-[calc(100%-360px)] lg:bg-[#FBF4E3]">
                <div className="w-11/12 xl:w-4/5 mx-auto mb-10">
                    <h1 className="mt-8 lg:mt-32 mb-6 lg:mb-12 text-3xl lg:text-4xl font-semibold text-black text-center">
                        Thanks
                    </h1>
                        <div className="text-xl flex justify-center content-center items-center">
                        Success! Your confirmation has been received.
                        </div>
                </div>
            </div>
        </div>
    );
};

export default ThankYou2;
