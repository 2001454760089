import VisaIcon from "../Assets/icons/payment/visa.png";
import MasterCardIcon from "../Assets/icons/payment/mastercard.png";

import AmazonIcon from "../Assets/icons/payment/amazon.png";
import AmericanexpressIcon from "../Assets/icons/payment/americanexpress.png";
import CirrusIcon from "../Assets/icons/payment/cirrus.png";
import DinersclubIcon from "../Assets/icons/payment/dinersclub.png";
import MaestroIcon from "../Assets/icons/payment/maestro.png";
import PaypalIcon from "../Assets/icons/payment/paypal.png";
import VisaelectronIcon from "../Assets/icons/payment/visaelectron.png";
import WesternunionIcon from "../Assets/icons/payment/westernunion.png";
import WorldpayIcon from "../Assets/icons/payment/worldpay.png";


export const iconMap = {
  'visa': VisaIcon,
  'mastercard': MasterCardIcon,
  'amazon': AmazonIcon,
  'americanexpress': AmericanexpressIcon,
  'cirrus': CirrusIcon,
  'dinersclub': DinersclubIcon,
  'maestro': MaestroIcon,
  'paypal': PaypalIcon,
  'visaelectron': VisaelectronIcon,
  'westernunion': WesternunionIcon,
  'worldpay': WorldpayIcon,
}

export const fee = {
    normal: "0",
    urgent: "29.00",
    mostUrgent: "0",
  };

  export const walletSizePrize= 19;

 export const coatingFee = 9;
 export const frameOldPrice=45.57;
 export const frameNewPrice=35.57;

 export  const sizePrice = {
    size4x6: 10,
    size5x7: 15,
    size8x10: 24,
    size11x14: 34,
    size16x20: 49,
  };


  export const sizes = [
    {
      size4x6: {
        size: "4x6",
        price: "10",
      },
    },
    {
      size5x7: {
        size: "5x7",
        price: "15",
      },
    },
    {
      size8x10: {
        size: "8x10",
        price: "24",
      },
    },
    {
      size11x14: {
        size: "11x14",
        price: "34",
      },
    },
    {
      size16x20: {
        size: "16x20",
        price: "49",
      },
    },
  ];
export const cards = [
    {
      number: "**** **** **** 7464",
      expiry: "12/26",
      cvv: "",
      cardIcon: VisaIcon,
    },
    {
      number: "**** **** **** 5863",
      expiry: "12/25",
      cvv: "",
      cardIcon: MasterCardIcon,
    },
  ];